import "./ComingSoonScreen.css";
import { SocialIcon } from 'react-social-icons'

const ComingSoonScreen = () => {
  return (
    <div className="coming-soon-screen">
      <img
        className="background-img"
        alt=""
        src="/one.png"
      />
      <div className="overlay" />
      <div className="item">
        <SocialIcon  network="twitter" bgColor="white" fgColor=" rgba(16, 94, 83)" />
        <SocialIcon  network="facebook" bgColor="white" fgColor=" rgba(16, 94, 83)" />
        <SocialIcon  network="linkedin" url="https://www.linkedin.com/company/shopsoma/about/" bgColor="white" fgColor=" rgba(16, 94, 83)" />
        <SocialIcon  network="instagram" url="https://www.instagram.com/shopsoma.africa/" bgColor="white" fgColor=" rgba(16, 94, 83)" />
      </div>
      <img
        className="logo"
        alt=""
        src="/shop-soma-logo-v-green-2@2x.png"
      />
      <div className="frame">
        <h1 className="coming-soon">COMING SOON</h1>
        
        <div className="about">
          Shop Soma is a Retail E-Commerce platform for African Luxury Designers
        </div>
      </div>
     </div>
  );
};

export default ComingSoonScreen;
